import { ClassNameMap, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import React from 'react';

interface ForwardLinkProps {
  isNewPage?: boolean;
  isUnderlined?: boolean;
  isDefault?: boolean;
  link: string;
  text?: string;
  className?: SxProps<Theme>;
  muistyle?: ClassNameMap<'root'>;
  variant: 'link' | 'download';
  onClickHandler?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLink-root': {
      color: '#3498DB',
      wordBreak: 'break-all',
    },
    cursor: 'pointer',
  },
}));

const ForwardLink: React.FC<ForwardLinkProps> = ({
  isUnderlined,
  isNewPage,
  link,
  children,
  text,
  onClickHandler,
  className,
  variant,
  muistyle,
}) => {
  const muiStyles = useStyles();

  return (
    <Link
      href={variant == 'link' ? link : undefined}
      classes={muistyle ? muistyle : muiStyles}
      sx={className ? className : undefined}
      onClick={onClickHandler}
      underline={isUnderlined ? 'always' : 'hover'}
      target={isNewPage ? '_blank' : undefined}
      rel={isNewPage ? 'noopener noreferrer' : undefined}
    >
      {!text ? children : text}
    </Link>
  );
};

export default ForwardLink;
