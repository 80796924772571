import ForwardLink from '@components/shared/fwdLink';
import { Alert } from '@mui/material';
import { NextPage } from 'next';

const NotFoundPage: NextPage = () => {
  return (
    <Alert severity="error" sx={{ fontSize: '1.6rem' }}>
      Uh oh, we can&apos;t seem to find the page you&apos;re looking for. Please{' '}
      <ForwardLink
        link={'/'}
        text={'click here'}
        isUnderlined
        variant={'link'}
      />{' '}
      to try again
    </Alert>
  );
};

export default NotFoundPage;
